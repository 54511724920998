angular.module("app")
    .factory('_mapAddTaskService', function ($rootScope, $compile, $q, _mapService) {
        var _mapAddTaskService = Object.create(_mapService);

        // Google Map drawingManager
        var drawingManager = null;

        var vars = _mapService.vars;

        var fieldId;    //feild id
        var grid;       //grid
        var manualPoints = []; //user positioned points
        var bandWidth;
        var combineGrid;
        var alongBands;
        var rows;       //number of rows
        var cols;       //number of columns
        var units;
        var scoutResults = {}; //scout assignment results objects
        var trackPoints = [];

        var EPS = 1e-12;

        var CONSTANT = _mapService.CONSTANT;
        var GRID_RECT_COLOR = "#000000";
        var SCOUT_TRACK_COLOR = "#9CF2DE";

        var PATH_LINE_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 4, strokeColor: "#f0bd5d", strokeWeight: 2, fillColor: "#FFFFFF", fillOpacity: 1};
        var SCOUT_POINT_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 5, strokeColor: "#FFFFFF", strokeWeight: 1, fillColor: "#FFFFFF", fillOpacity: 1};
        var TRACK_POINT_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 2, strokeColor: "#663300", strokeWeight: 0, fillColor: "#663300", fillOpacity: 0.5};

        var DONE_CM_POINT_COLOR = '#93e94b';
        var PARTIAL_CM_POINT_COLOR = '#00ffff';
        var WARNING_CM_POINT_COLOR = '#ffeb06';
        var DANGER_CM_POINT_COLOR = "#F56423";

        var ZORRO_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 0};

        var ROTATE_ICON = {
            path: 'M451.878,215.896c-25.196,0.229-50.377,0.358-75.573,0.571c4.233,43.372-10.049,88.179-43.26,121.339c-58.959,58.944-154.484,58.944-213.411,0c-58.943-58.894-58.943-154.436,0-213.379c50.278-50.312,127.17-57.461,185.283-21.907c-19.691,19.463-42.868,42.51-42.868,42.51c-16.679,17.72,0.277,29.463,11.516,29.529h147.237c5.636,0,10.162-4.561,10.179-10.196V18.332c0.685-13.763-14.414-26.548-29.283-11.678c0,0-24.937,24.675-42.251,41.858C270.959-16.164,146.23-8.835,66.293,71.086c-88.391,88.391-88.391,231.719,0,320.094c88.374,88.358,231.703,88.358,320.078,0C434.466,343.117,456.145,278.766,451.878,215.896z',
            fillColor: '#FFFFFF',
            fillOpacity: 1,
            scale: 0.03,
            strokeColor: '#000000',
            strokeWeight: 1,
            anchor: new google.maps.Point(220, 220)
        };

        var DIRECTION_ICON = {
            path: 'M490.498,239.278l-109.632-99.929c-3.046-2.474-6.376-2.95-9.993-1.427c-3.613,1.525-5.427,4.283-5.427,8.282v63.954H9.136c-2.666,0-4.856,0.855-6.567,2.568C0.859,214.438,0,216.628,0,219.292v54.816c0,2.663,0.855,4.853,2.568,6.563c1.715,1.712,3.905,2.567,6.567,2.567h356.313v63.953c0,3.812,1.817,6.57,5.428,8.278c3.62,1.529,6.95,0.951,9.996-1.708l109.632-101.077c1.903-1.902,2.852-4.182,2.852-6.849C493.356,243.367,492.401,241.181,490.498,239.278z',
            fillColor: '#FFFFFF',
            fillOpacity: 1,
            scale: 0.07,
            strokeColor: '#000000',
            strokeWeight: 1,
            anchor: new google.maps.Point(0, 420) //220 220
        };

        var infowindow = new google.maps.InfoWindow({});

        var RESIZE_ICON = {path: google.maps.SymbolPath.CIRCLE, scale: 4, strokeColor: "#000000", strokeWeight: 2, fillColor: "#FFFFFF", fillOpacity: 1};

        _mapAddTaskService.highlightField = function(field) {
            _mapService.drawField(field);

            if (_.has(vars.fieldShapes, field.id)) {
                var shape = vars.fieldShapes[field.id];

                google.maps.event.addListener(shape.polygon, "mouseover", function (a, b) {
                    setTimeout(function(){
                        var elements = $(vars.gmap.getDiv()).find(':not(.gmnoprint)[style*="cursor: pointer"]');
                        elements.css('cursor', 'default');
                    }, 0);
                });

                vars.fieldShapes[field.id] = shape;
            }
        };


        _mapAddTaskService.getScoutPointsAsGeoJSONFromManualPoins = function () {
            var result = { "type": "FeatureCollection", "features": []};

            manualPoints.forEach(p => {
                var checkedActivities = Object.keys(_.object(_.pairs(p.activities).filter(function (activityData) {
                    return activityData[1].value;
                })));

                result.features.push({
                    "type": "Feature",
                    "properties": {"requiredActivities": checkedActivities, "cellIndex" : [0, 0], "affectedCells" : [[0, 0]]},
                    "geometry": {
                        "type": "Point",
                        "coordinates": [p.getPosition().lng(), p.getPosition().lat()]
                    }
                });
            });

            return result;
        };

        _mapAddTaskService.getScoutPointsAsGeoJSON = function () {
            var result = { "type": "FeatureCollection", "features": []};

            grid.pathLines.forEach(pathLine => {
                pathLine.scoutPoints.forEach(p => {
                    var checkedActivities = Object.keys(_.object(_.pairs(p.activities).filter(function (activityData) {
                        return activityData[1].value;
                    })));

                    result.features.push({
                        "type": "Feature",
                        "properties": {"requiredActivities": checkedActivities, "cellIndex" : [p.row, p.col], "affectedCells" : (p.combinedCell ? p.combinedCell.combinedCellIndexes : [[p.row, p.col]])},
                        "geometry": {
                            "type": "Point",
                            "coordinates": [p.getPosition().lng(), p.getPosition().lat()]
                        }
                    });
                });
            });
            return result;
        };

        _mapAddTaskService.scoutPointClick = function(pointIndex) {
            _.each(scoutResults.scoutPoints, function(p) {
                if (p.pointIndex == pointIndex) {
                    if (p.active) {
                        var icon = p.getIcon();
                        icon.scale = SCOUT_POINT_ICON.scale;
                        icon.strokeColor = SCOUT_POINT_ICON.strokeColor;

                        p.setIcon(icon);
                        p.setLabel(null);
                        p.active = false;
                        p.setZIndex(p.zIndexCopy);
                        $rootScope.$safeApply(() => {
                            $rootScope.$broadcast('mapService:scoutPointClick', null);
                        });
                    } else {
                        var icon = p.getIcon();
                        icon.scale = 10;
                        var textColor = "white";
                        if (p.status == "NEW") {
                            textColor = "black";
                            icon.strokeColor = "gray";
                        }
                        p.setIcon(icon);
                        p.setLabel({text: ""+p.pointIndex, color: textColor, fontSize: "12"});
                        p.setZIndex(CONSTANT.ZINDEX.MAX);
                        p.active = true;
                        $rootScope.$safeApply(() => {
                            $rootScope.$broadcast('mapService:scoutPointClick', p.result);
                        });
                    }
                } else if (p.active) {
                    var icon = p.getIcon();
                    icon.scale = SCOUT_POINT_ICON.scale;
                    icon.strokeColor = SCOUT_POINT_ICON.strokeColor;

                    p.setIcon(icon);
                    p.setLabel(null);
                    p.active = false;
                    p.setZIndex(p.zIndexCopy);
                }
            });
        };

        _mapAddTaskService.drawTaskResults = function(task) {
            var results = task.points;

            if (results) {
                scoutResults.scoutPoints = [];
                scoutResults.combinedCells = [];
                results.forEach(r => {
                    var style = _.clone(SCOUT_POINT_ICON);
                    if (r.status == "DONE") {
                        style.fillColor = DONE_CM_POINT_COLOR;
                    }
                    if (r.status == "PARTIAL") {
                        style.fillColor = PARTIAL_CM_POINT_COLOR;
                    }
                    var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(r.lat, r.lon),
                        map: _mapService.vars.gmap,
                        icon: style,
                        zIndex: CONSTANT.ZINDEX.POINTS + r.pointIndex,
                        zIndexCopy: CONSTANT.ZINDEX.POINTS + r.pointIndex,
                        cellIndex: r.cellIndex,
                        status: r.status,
                        pointIndex: r.pointIndex,
                        result: r
                    });

                    google.maps.event.addListener(marker, "click", function (pp) {
                        _mapAddTaskService.scoutPointClick(r.pointIndex);
                    });

                    scoutResults.scoutPoints.push(marker);

                    if (_.size(r.affectedCells) > 1) { //draw group cell
                        var col = r.affectedCells[0][1];
                        var minRow = _.min(_.map(r.affectedCells, function(e){ return e[0]; }));
                        var maxRow = _.max(_.map(r.affectedCells, function(e){ return e[0]; }));
                        var p1 = _mapAddTaskService.getP1(minRow,col,task.grid); //row col
                        var p2 = _mapAddTaskService.getP2(minRow,col,task.grid); //row col
                        var p3 = _mapAddTaskService.getP3(maxRow,col,task.grid); //row col
                        var p4 = _mapAddTaskService.getP4(maxRow,col,task.grid); //row col
                        scoutResults.combinedCells.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p1, p2, p3, p4, p1], options: {strokeColor: CONSTANT.GRID_CELL_COLOR, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID_GROUP}}));
                    }
                });
            }
        };

        _mapAddTaskService.highlightPoints = function(pointIndexes) {
            _.each(scoutResults.scoutPoints, function (e) {
                if (pointIndexes.has(e.pointIndex)) {
                    e.icon.strokeColor = "#F8E71C";
                    e.icon.strokeWeight = 3;
                }
            });
        };

        _mapAddTaskService.clearPointsHighlight = function() {
            _.each(scoutResults.scoutPoints, function (e) {
                e.icon.strokeColor = "#FFFFFF";
                e.icon.strokeWeight = 1;
            });
        };

        _mapAddTaskService.drawPADangerMap = function(matrix) {
            for (var i = 0; i < matrix.length; i++) {
                for (var j = 0; j < matrix[i].length; j++) {
                    if (matrix[i][j]) {
                        scoutResults.scoutGrid[j * matrix.length + i].setOptions({
                            fillColor: matrix[i][j],
                            fillOpacity: 0.8
                        });
                    } else {
                        scoutResults.scoutGrid[j * matrix.length + i].setOptions({
                            fillOpacity: 0
                        });
                    }
                }
            }
        };

        _mapAddTaskService.resetPADangerMap = function () {
            _.each(scoutResults.scoutGrid, function (poly) {
                poly.setOptions({
                    fillOpacity: 0
                });
            });
        };

        _mapAddTaskService.resetRegularDangerMap = function () {

            _.each(scoutResults.scoutPoints, function(p){
                var icon = p.getIcon();
                icon.fillColor = SCOUT_POINT_ICON.fillColor;
                if (p.status == "DONE") {
                    icon.fillColor = DONE_CM_POINT_COLOR;
                }
                if (p.status == "PARTIAL") {
                    icon.fillColor = PARTIAL_CM_POINT_COLOR;
                }
                p.setIcon(icon);
            });
        };

        _mapAddTaskService.drawDangerMap = function(matrix) {
           _.each(scoutResults.scoutPoints, function(p){
               var icon = p.getIcon();
               if (p.status == "DONE") {
                   icon.fillColor = DONE_CM_POINT_COLOR;
               }
               if (p.status == "PARTIAL") {
                   icon.fillColor = PARTIAL_CM_POINT_COLOR;
               }
               if (matrix[p.cellIndex[0]][p.cellIndex[1]] == 'warning') {
                   icon.fillColor = WARNING_CM_POINT_COLOR;
               }
               if (matrix[p.cellIndex[0]][p.cellIndex[1]] == 'danger') {
                   icon.fillColor = DANGER_CM_POINT_COLOR;
               }
               p.setIcon(icon);
           });
        };

        _mapAddTaskService.drawTaskGrid = function(task) {
            var gridData = task.grid;
            scoutResults.scoutGrid = [];

            var color = task.gridGroupSize > 1 ? CONSTANT.GRID_PALE_CELL_COLOR : CONSTANT.GRID_CELL_COLOR;
            for (var i = 0; i < gridData.nx; i++) { //col
                for (var j = 0; j < gridData.ny; j++) { //row
                    var p1 = _mapAddTaskService.getP1(j,i,gridData); //row col
                    var p2 = _mapAddTaskService.getP2(j,i,gridData); //row col
                    var p3 = _mapAddTaskService.getP3(j,i,gridData); //row col
                    var p4 = _mapAddTaskService.getP4(j,i,gridData); //row col
                    scoutResults.scoutGrid.push(new google.maps.Polygon({map: _mapService.vars.gmap, path: [p1, p2, p3, p4, p1],
                        options: {strokeColor: color, strokeWeight: 1, fillOpacity: 0, zIndex: CONSTANT.ZINDEX.GRID}}));
                }
            }
        };

        _mapAddTaskService.clearCropMonitor = function() {
            _.each(scoutResults.scoutPoints, function(p) {
                p.setMap(null);
            });
            _.each(scoutResults.scoutGrid, function(p) {
                p.setMap(null);
            });
            _.each(scoutResults.combinedCells, function(p) {
                p.setMap(null);
            });
            if (scoutTrack) {
                scoutTrack.setMap(null);
            }
        };

        function checkPointInside(p, r, c, gridInfo) {
            var p1 = _mapAddTaskService.getP1(r, c, gridInfo); //row col
            var p2 = _mapAddTaskService.getP2(r, c, gridInfo); //row col
            var p3 = _mapAddTaskService.getP3(r, c, gridInfo); //row col
            var p4 = _mapAddTaskService.getP4(r, c, gridInfo); //row col

            return google.maps.geometry.poly.containsLocation(p, new google.maps.Polygon({paths: [p1, p2, p3, p4]}));
        }

        _mapAddTaskService.getGridFromManualPoints = function() {
            var result = {};
            var gap = 15; //meters

            var minLat = _.min(_.map(manualPoints, function(p){ return p.getPosition().lat(); }));
            var minLng = _.min(_.map(manualPoints, function(p){ return p.getPosition().lng(); }));
            var maxLat = _.max(_.map(manualPoints, function(p){ return p.getPosition().lat(); }));
            var maxLng = _.max(_.map(manualPoints, function(p){ return p.getPosition().lng(); }));

            var p1 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(maxLat, minLng), gap, 0);
            p1 = google.maps.geometry.spherical.computeOffset(p1, gap, -90);

            var p2 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(maxLat, maxLng), gap, 0);
            p2 = google.maps.geometry.spherical.computeOffset(p2, gap, 90);

            var p4 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(minLat, minLng), gap, 180);
            p4 = google.maps.geometry.spherical.computeOffset(p4, gap, -90);

            result.lat0 = p1.lat();
            result.lon0 = p1.lng();
            result.nx = 1;
            result.ny = 1;

            result.angle = 90;
            result.dx = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
            result.dy = google.maps.geometry.spherical.computeDistanceBetween(p1, p4);

            return result;
        };

        _mapAddTaskService.getGrid = function() {
            var result = {};
            result.lat0 = grid.polyline.getPath().getAt(0).lat();
            result.lon0 = grid.polyline.getPath().getAt(0).lng();
            result.nx = cols;
            result.ny = rows;
            result.angle = google.maps.geometry.spherical.computeHeading(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1));
            result.dx = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1)) / cols;
            result.dy = bandWidth;

            var signedArea = google.maps.geometry.spherical.computeSignedArea(grid.polyline.getPath());
            if (signedArea > 0) {
                console.log("grid signedArea > 0");
                return null;
            }

            var goodIndexes = true;
            grid.pathLines.forEach(pathLine => {
                pathLine.scoutPoints.forEach(p => {
                    if (p.row >= rows) {
                        goodIndexes = false;
                    }
                    if (p.col >= cols) {
                        goodIndexes = false;
                    }
                    goodIndexes = goodIndexes && checkPointInside(p.getPosition(), p.row, p.col, result);
                    if (!checkPointInside(p.getPosition(), p.row, p.col, result)) {
                        console.log("error 22");
                    }
                });
            });

            if (combineGrid > 1) {
                var uniqueIndexes = [];
                _.each(grid.combinedCells, function (cell) {
                    _.each(cell.combinedCellIndexes, function(arr){
                        if (arr[0] >= rows) {
                            goodIndexes = false;
                        }
                        if (arr[1] >= cols) {
                            goodIndexes = false;
                        }
                        var str = arr[0] + "_" +  arr[1];
                        if (_.contains(uniqueIndexes, str)) {
                            goodIndexes = false;
                            console.log("error 33");
                        }
                        uniqueIndexes.push(str);
                    });
                });

                grid.pathLines.forEach(pathLine => {
                    pathLine.scoutPoints.forEach(p => {
                        var strInd = p.row + "_" + p.col;
                        goodIndexes = goodIndexes && _.contains(_.map(p.combinedCell.combinedCellIndexes, function(arr) {return arr[0] + "_" +  arr[1];}), strInd);
                    });
                });
            }

            if (!goodIndexes) {
                return null;
            }

            return result;
        };

        _mapAddTaskService.getManualPoints = function () {
            return manualPoints;
        };

        _mapAddTaskService.addScoutPoints = function () {

            drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingControl: false,
                drawingControlOptions: {
                    drawingModes: [ google.maps.drawing.OverlayType.MARKER ]
                },
                rectangleOptions: {
                    fillOpacity: 0,
                    zIndex: CONSTANT.ZINDEX.GRID
                },
                markerOptions: {
                    crossOnDrag: false,
                    draggable: true,
                    icon: SCOUT_POINT_ICON,
                    zIndex: CONSTANT.ZINDEX.POINTS
                }
            });
            drawingManager.setMap(_mapService.vars.gmap);

            google.maps.event.addListener(drawingManager, "markercomplete", function (p) {
                p.activities = {};
                p.id = _.uniqueId();

                p.addListener('click', function(e) {
                    var marker = this;
                    if (marker.activities) {
                        var scope = $rootScope.$new();
                        angular.extend(scope, {marker: this});
                        var content = angular.element("<div style='line-height:1.35;overflow:hidden;white-space:nowrap;'><frt-activity-types marker='marker'></frt-activity-types></div>"),
                            compiled = $compile(content)(scope);
                        infowindow.setContent(content[0]);
                        infowindow.open(_mapService.vars.gmap, this);
                    }
                });

                p.addListener('rightclick', function(e) {
                    var marker = this;
                    marker.setMap(null);
                    for (var i = manualPoints.length - 1; i >= 0; i--) {
                        if (manualPoints[i].id == marker.id) {
                            manualPoints.splice(i, 1);
                        }
                    }

                    $rootScope.$safeApply(() => {
                        $rootScope.$broadcast('mapService:manualPointAdded');
                    });
                });

                manualPoints.push(p);

                $rootScope.$safeApply(() => {
                    $rootScope.$broadcast('mapService:manualPointAdded');
                });

            });

        };

        _mapAddTaskService.editScoutPoints = function () {
            drawingManager.setDrawingMode(null);


        };

        /**
         * Init Drawing Manager
         *
         * @param field
         */
        _mapAddTaskService.editGrid = function(field, b, c, u, cG, along_bands) {
            bandWidth = b;
            combineGrid = cG;
            cols = c;
            units = u;
            alongBands = along_bands;

            fieldId = field;

            drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
                drawingControl: false,
                drawingControlOptions: {
                    drawingModes: [ google.maps.drawing.OverlayType.RECTANGLE ]
                },
                rectangleOptions: {
                    fillOpacity: 0,
                    zIndex: CONSTANT.ZINDEX.GRID
                }
            });
            drawingManager.setMap(_mapService.vars.gmap);

            google.maps.event.addListener(drawingManager, "rectanglecomplete", function (p) {

                drawingManager.setDrawingMode(null);

                var borderPath = [ new google.maps.LatLng(p.getBounds().getNorthEast().lat(), p.getBounds().getSouthWest().lng()),
                    p.getBounds().getNorthEast(),
                    new google.maps.LatLng(p.getBounds().getSouthWest().lat(), p.getBounds().getNorthEast().lng()),
                    p.getBounds().getSouthWest(),
                    new google.maps.LatLng(p.getBounds().getNorthEast().lat(), p.getBounds().getSouthWest().lng())];

                var signedArea = google.maps.geometry.spherical.computeSignedArea(borderPath);
                if (signedArea > 0) {
                    var xBorderPath = [borderPath[0],borderPath[3],borderPath[2],borderPath[1],borderPath[4]];
                    borderPath = xBorderPath;
                }

                grid = {
                    polyline: new google.maps.Polyline({map: _mapService.vars.gmap,
                        path: borderPath,
                        options: {strokeColor: GRID_RECT_COLOR, strokeWeight: 2, zIndex: CONSTANT.ZINDEX.GRID_BORDER}}),
                    rows: [],
                    cols: [],
                    pathLines: [],
                    combinedCells: [],
                    clear: function() {
                        this.rows.forEach(line => line.setMap(null));
                        this.rows = [];

                        this.cols.forEach(line => line.setMap(null));
                        this.cols = [];

                        this.combinedCells.forEach(c => c.setMap(null));
                        this.combinedCells = [];

                        this.pathLines.forEach(pathLine => {
                            pathLine.p1marker.setMap(null);
                            pathLine.p2marker.setMap(null);
                            pathLine.p1markerHandler.setMap(null);
                            pathLine.p2markerHandler.setMap(null);
                            pathLine.scoutPoints.forEach(scoutPoint => scoutPoint.setMap(null));
                            pathLine.setMap(null);
                        });
                        this.pathLines = [];
                        if (this.directionMarker) {
                            this.directionMarker.setMap(null);
                        }
                    },
                    getCenter: function() {
                        return middle(this.polyline.getPath().getAt(0), this.polyline.getPath().getAt(2));
                    },
                    placeControls: function() {
                        this.rotateMarker.setPosition(middle(this.polyline.getPath().getAt(1), this.polyline.getPath().getAt(2)));
                        this.polyline.getPath().forEach((p, i) => {
                            if (i < 4) {
                                this.resizeMarkers[i].setPosition(p);
                            }
                        });
                    }
                };

                grid.rotateMarker = new google.maps.Marker({
                    position: middle(grid.polyline.getPath().getAt(1), grid.polyline.getPath().getAt(2)),
                    map: _mapService.vars.gmap,
                    draggable: true,
                    crossOnDrag: false,
                    icon: ROTATE_ICON});

                grid.resizeMarkers = [];

                grid.polyline.getPath().forEach((p, i) => {
                    if (i < 4) {
                        var marker = new google.maps.Marker({
                            position: p,
                            map: _mapService.vars.gmap,
                            draggable: true,
                            crossOnDrag: false,
                            icon: RESIZE_ICON,
                            zIndex: CONSTANT.ZINDEX.RESIZE_MARKER});
                        grid.resizeMarkers.push(marker);

                        google.maps.event.addListener(marker, "dragstart", function (pp) {
                            grid.clear();
                            grid.oldPath = _.clone(grid.polyline.getPath());
                        });

                        google.maps.event.addListener(marker, "drag", function (pp) {
                            if (i == 0) {
                                grid.polyline.getPath().setAt(0, pp.latLng);
                                grid.polyline.getPath().setAt(1, projectPointOnLine(grid.oldPath.getAt(1), grid.oldPath.getAt(2), pp.latLng));
                                grid.polyline.getPath().setAt(3, projectPointOnLine(grid.oldPath.getAt(2), grid.oldPath.getAt(3), pp.latLng));
                                grid.polyline.getPath().setAt(4, pp.latLng);
                            }
                            if (i == 1) {
                                grid.polyline.getPath().setAt(1, pp.latLng);
                                grid.polyline.getPath().setAt(2, projectPointOnLine(grid.oldPath.getAt(2), grid.oldPath.getAt(3), pp.latLng));
                                grid.polyline.getPath().setAt(0, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(3), pp.latLng));
                                grid.polyline.getPath().setAt(4, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(3), pp.latLng));
                            }
                            if (i == 2) {
                                grid.polyline.getPath().setAt(2, pp.latLng);
                                grid.polyline.getPath().setAt(1, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(1), pp.latLng));
                                grid.polyline.getPath().setAt(3, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(3), pp.latLng));
                            }
                            if (i == 3) {
                                grid.polyline.getPath().setAt(3, pp.latLng);
                                grid.polyline.getPath().setAt(0, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(1), pp.latLng));
                                grid.polyline.getPath().setAt(4, projectPointOnLine(grid.oldPath.getAt(0), grid.oldPath.getAt(1), pp.latLng));
                                grid.polyline.getPath().setAt(2, projectPointOnLine(grid.oldPath.getAt(1), grid.oldPath.getAt(2), pp.latLng));
                            }

                            grid.placeControls();
                        });

                        google.maps.event.addListener(marker, "dragend", function (pp) {
                            var borderPath = grid.polyline.getPath();
                            var signedArea = google.maps.geometry.spherical.computeSignedArea(borderPath);
                            if (signedArea > 0) {
                                var xBorderPath = [borderPath.getAt(0),borderPath.getAt(3),borderPath.getAt(2),borderPath.getAt(1),borderPath.getAt(4)];
                                grid.polyline.setPath(xBorderPath);
                                grid.placeControls();
                            }
                            _mapAddTaskService.drawGrid();
                        });
                    }
                });

                google.maps.event.addListener(grid.rotateMarker, "dragstart", function (pp) {
                    grid.clear();
                    grid.oldAngle = google.maps.geometry.spherical.computeHeading(grid.getCenter(), pp.latLng);
                });

                google.maps.event.addListener(grid.rotateMarker, "drag", function (pp) {
                    grid.newAngle = google.maps.geometry.spherical.computeHeading(grid.getCenter(), pp.latLng);
                    var newPath = [];
                    var dAngle = grid.newAngle - grid.oldAngle;
                    var center = grid.getCenter();
                    var len = google.maps.geometry.spherical.computeDistanceBetween(center, grid.polyline.getPath().getAt(0));
                    grid.polyline.getPath().forEach((p, i) => {
                        if (i < 4) {
                            var h = google.maps.geometry.spherical.computeHeading(center, p);
                            newPath.push(google.maps.geometry.spherical.computeOffset(center, len, h + dAngle));
                        }
                    });
                    newPath.push(newPath[0]);
                    grid.polyline.setPath(newPath);
                    grid.placeControls();
                    grid.oldAngle = grid.newAngle;
                });

                google.maps.event.addListener(grid.rotateMarker, "dragend", function (pp) {
                    _mapAddTaskService.drawGrid();
                });

                p.setMap(null);
                _mapAddTaskService.drawGrid();
            });
        };

        /**
         * Draw grid
         *
         * @param band_width
         * @param cols_count
         * @param combine_grid
         */
        _mapAddTaskService.drawGrid = function(band_width, cols_count, combine_grid, along_bands) {
            if (!_.isUndefined(band_width)) { //call with parameters
                bandWidth = band_width;
                cols = cols_count;
                combineGrid = combine_grid;
                alongBands = along_bands;
            }

            rows = Math.max(1, (Math.round(google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(1), grid.polyline.getPath().getAt(2)) / bandWidth)));

            if (combineGrid > rows) {
                combineGrid = rows;
            }

            grid.clear();

            // p1----------------p2
            // |                  |
            // p4----------------p3

            roundGrid(grid, rows, cols, bandWidth);

            var p1 = grid.polyline.getPath().getAt(0);
            var p2 = grid.polyline.getPath().getAt(1);
            var p3 = grid.polyline.getPath().getAt(2);
            var p4 = grid.polyline.getPath().getAt(3);

            var color = combineGrid > 1 ? CONSTANT.GRID_PALE_CELL_COLOR : CONSTANT.GRID_CELL_COLOR;

            var arr1 = splitLine(p1, p4, rows);
            var arr2 = splitLine(p2, p3, rows);
            arr1.forEach((p, i) => {
                grid.rows.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p, arr2[i]], options: {strokeColor: color, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID}}));
            });

            arr1 = splitLine(p1, p2, cols);
            arr2 = splitLine(p4, p3, cols);
            arr1.forEach((p, i) => {
                grid.cols.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p, arr2[i]], options: {strokeColor: color, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID}}));
            });

            createGroupCells(p1, p2, p3, p4, rows, cols, combineGrid, grid);

            drawPath();
            updateInfo();


            if (grid.directionMarker) {
                grid.directionMarker.setMap(null);
            }

            var i = _.clone(DIRECTION_ICON);
            i.rotation = google.maps.geometry.spherical.computeHeading(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1)) - 90;

            grid.directionMarker = new google.maps.Marker({
                position: grid.polyline.getPath().getAt(0),
                map: _mapService.vars.gmap,
                draggable: false,
                icon: i});


            $rootScope.$safeApply(() => {
                $rootScope.$broadcast('mapService:gridCompleted', grid);
            });
        };

        function createGroupCells(p1, p2, p3, p4, rows, cols, combineGrid, grid) {
            //create and draw combined cells
            if (combineGrid > 1) {
                var h = google.maps.geometry.spherical.computeHeading(p1, p2);
                var width = google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / cols;
                var height = google.maps.geometry.spherical.computeDistanceBetween(p2, p3) / rows;
                for (var i = rows - 1; i + 1 >= 0; i-=combineGrid) {
                    if (i - combineGrid + 1 >= 0) {
                        var combinedCell;
                        var gP1, gP2, gP3, gP4;
                        for (var j = 1; j <= cols; j++) {
                            gP1 = google.maps.geometry.spherical.computeOffset(p1, height * (1 + i - combineGrid), h + 90);
                            gP1 = google.maps.geometry.spherical.computeOffset(gP1, width * (j-1), h);
                            gP4 = google.maps.geometry.spherical.computeOffset(gP1, height * combineGrid, h + 90);
                            gP2 = google.maps.geometry.spherical.computeOffset(gP1, width, h);
                            gP3 = google.maps.geometry.spherical.computeOffset(gP4, width, h);
                            combinedCell = new google.maps.Polyline({map: _mapService.vars.gmap, path: [gP1, gP2, gP3, gP4, gP1],
                                options: {strokeColor: CONSTANT.GRID_CELL_COLOR, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID_GROUP}});

                            combinedCell.combinedCellIndexes = [];
                            for (var k = 1+i-combineGrid; k <= i; k++) {
                                combinedCell.combinedCellIndexes.push([k, j-1]);
                            }

                            grid.combinedCells.push(combinedCell);
                        }
                    }
                }
            }
        }

        _mapAddTaskService.clearGrid = function() {
            if (grid) {
                grid.clear();
            }
            if (trackPoints) {
                _.each(trackPoints, function (p) {
                    p.setMap(null);
                });
            }
        };

        _mapAddTaskService.switchMode = function() {
            if (drawingManager) {
                drawingManager.setDrawingMode(null);
            }
            if (grid) {
                if (grid.polyline) {
                    grid.polyline.setMap(null);
                }
                if (grid.rotateMarker) {
                    grid.rotateMarker.setMap(null);
                }
                if (grid.directionMarker) {
                    grid.directionMarker.setMap(null);
                }
                if (grid.resizeMarkers) {
                    grid.resizeMarkers.forEach(r => {
                        r.setMap(null);
                    });
                }
            }
            _.each(manualPoints, function(point) {
                point.setMap(null);
            });
            manualPoints.splice(0);

            _mapAddTaskService.clearGrid();
        };

        var scoutTrack = null;

        _mapAddTaskService.trackLength = function(points) {
            var track = new google.maps.Polyline();
            _.each(points, function(point) {
                track.getPath().push(new google.maps.LatLng(point.getY(), point.getX()));
            });
            return google.maps.geometry.spherical.computeLength(track.getPath());
        };

        _mapAddTaskService.fieldArea = function(fieldId) {
            return google.maps.geometry.spherical.computeArea(_mapService.vars.fieldShapes[fieldId].polygon.getPath());
        };

        _mapAddTaskService.drawScoutTrack = function(points) {
            scoutTrack = new google.maps.Polyline({map: _mapService.vars.gmap, options: {strokeColor: SCOUT_TRACK_COLOR, strokeWeight: 6, zIndex: CONSTANT.ZINDEX.POINTS}});
            _.each(points, function(point) {
                scoutTrack.getPath().push(new google.maps.LatLng(point.getY(), point.getX()));
            });
        };

        _mapAddTaskService.hideScoutTrack = function() {
            if (scoutTrack) {
                scoutTrack.setMap(null);
            }
        };

        _mapAddTaskService.showTrackAndGrid = function(trackInfo, fId, u, combine_grid, along_bands) {
            units = u;
            fieldId = fId;
            var gridToReturn = null;
            if (trackInfo.grid) {
                var gridData = trackInfo.grid;

                rows = gridData.ny;
                cols = gridData.nx;
                bandWidth = gridData.dy;
                combineGrid = combine_grid;
                alongBands = along_bands;
                if (combineGrid > rows) {
                    combineGrid = rows;
                }

                grid = {cols: [], pathLines: [], rows: [], combinedCells: []};

                grid.clear = function() {
                    this.cols.forEach(line => line.setMap(null));
                    this.cols = [];

                    this.rows.forEach(line => line.setMap(null));
                    this.rows = [];

                    this.combinedCells.forEach(c => c.setMap(null));
                    this.combinedCells = [];

                    this.pathLines.forEach(pathLine => {
                        pathLine.p1marker.setMap(null);
                        pathLine.p2marker.setMap(null);
                        pathLine.p1markerHandler.setMap(null);
                        pathLine.p2markerHandler.setMap(null);
                        pathLine.scoutPoints.forEach(scoutPoint => scoutPoint.setMap(null));
                        pathLine.setMap(null);
                    });
                    this.pathLines = [];
                    grid.polyline.setMap(null);
                };

                var p1 = _mapAddTaskService.getP1(0,0,gridData);                            //row col
                var p2 = _mapAddTaskService.getP2(0,gridData.nx-1,gridData);                //row col
                var p3 = _mapAddTaskService.getP3(gridData.ny-1,gridData.nx-1,gridData);    //row col
                var p4 = _mapAddTaskService.getP4(gridData.ny-1,0,gridData);                //row col

                grid.polyline = new google.maps.Polyline({map: _mapService.vars.gmap,
                    path: [ p1, p2, p3, p4, p1],
                    options: {strokeColor: GRID_RECT_COLOR, strokeWeight: 2, zIndex: CONSTANT.ZINDEX.GRID_BORDER}});

                var color = combineGrid > 1 ? CONSTANT.GRID_PALE_CELL_COLOR : CONSTANT.GRID_CELL_COLOR;

                var arr1 = splitLine(p1, p4, rows);
                var arr2 = splitLine(p2, p3, rows);
                arr1.forEach((p, i) => {
                    grid.rows.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p, arr2[i]], options: {strokeColor: color, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID}}));
                });

                var arr1 = splitLine(p1, p2, gridData.nx);
                var arr2 = splitLine(p4, p3, gridData.nx);
                arr1.forEach((p, i) => {
                    grid.cols.push(new google.maps.Polyline({map: _mapService.vars.gmap, path: [p, arr2[i]], options: {strokeColor: color, strokeWeight: 1, zIndex: CONSTANT.ZINDEX.GRID}}));
                });

                createGroupCells(p1, p2, p3, p4, rows, cols, combineGrid, grid);

                drawPath();
                updateInfo();
                grid.gridData = gridData;
                gridToReturn = grid;
            }

            trackPoints = [];
            if (trackInfo.track) {
                _.each(trackInfo.track.features, function (p) {
                    trackPoints.push(new google.maps.Marker({
                        position: new google.maps.LatLng(p.properties.lat, p.properties.lon),
                        map: _mapService.vars.gmap,
                        options: {draggable: false},
                        icon: TRACK_POINT_ICON
                    }));
                });
            }

            return gridToReturn;
        };

        function roundGrid(grid, rows, cols, bandWidth) {
            var width = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1)) / cols;
            var height = bandWidth;
            var h1 = google.maps.geometry.spherical.computeHeading(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1));
            var h2 = google.maps.geometry.spherical.computeHeading(grid.polyline.getPath().getAt(1), grid.polyline.getPath().getAt(2));

            var p2 = google.maps.geometry.spherical.computeOffset(grid.polyline.getPath().getAt(0), width * cols, h1);
            var p3 = google.maps.geometry.spherical.computeOffset(p2, height * rows, h2);
            var p4 = google.maps.geometry.spherical.computeOffset(grid.polyline.getPath().getAt(0), height * rows, h2);

            var newPath = [grid.polyline.getPath().getAt(0), p2, p3, p4, grid.polyline.getPath().getAt(0)];
            grid.polyline.setPath(newPath);
            grid.placeControls();
        }

        function updateInfo() {
            grid.info = {};
            grid.info.rows = rows;
            grid.info.cols = cols;
            grid.info.width = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1)) / cols;
            grid.info.height = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(1), grid.polyline.getPath().getAt(2)) / rows;
            grid.info.pointsNum = 0;
            grid.pathLines.forEach(pathLine => { grid.info.pointsNum = grid.info.pointsNum + pathLine.scoutPoints.length; });
            var area = google.maps.geometry.spherical.computeArea(_mapService.vars.fieldShapes[fieldId].polygon.getPath());
            if (units == 'METRIC') {
                area = area / 10000; //ha
            } else {
                area = area / 4046.86; //acr
            }
            if (grid.info.pointsNum > 0) {
                grid.info.density = area / grid.info.pointsNum;
            } else {
                grid.info.density = 0;
            }
        }

        function movePathLine(marker, pp) {
            var p = projectPointOnLine(marker.a1, marker.a2, pp.latLng);
            if (testPointInside(marker.a1, marker.a2, p)) {
                marker.setPosition(p);
            }
        }

        function projectPointOnLine(l1, l2, p) {
            var overlayProjection = _mapService.vars.gmap.getProjection();

            var x1 = overlayProjection.fromLatLngToPoint(l1).x;
            var y1 = overlayProjection.fromLatLngToPoint(l1).y;
            var x2 = overlayProjection.fromLatLngToPoint(l2).x;
            var y2 = overlayProjection.fromLatLngToPoint(l2).y;

            var prP = overlayProjection.fromLatLngToPoint(p);
            var x3 = prP.x;
            var y3 = prP.y;

            if (Math.abs(x2-x1) > EPS && Math.abs(y2-y1) > EPS) {
                var k1 = (y2 - y1) / (x2 - x1);
                var b1 =  y1 - x1 * k1;
                var k2 = -1/k1;
                var b2 = y3 - k2 * x3;
                var x4 = (b2-b1)/(k1-k2);
                var y4 = k1 * x4 + b1;
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x4, y4));
            } else if (Math.abs(x2-x1) > EPS) {
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x3, y1));
            } else {
                return overlayProjection.fromPointToLatLng(new google.maps.Point(x1, y3));
            }
        }

        function testPointInside(l1, l2, p) {
            var overlayProjection = _mapService.vars.gmap.getProjection();
            var x1 = overlayProjection.fromLatLngToPoint(l1).x;
            var y1 = overlayProjection.fromLatLngToPoint(l1).y;
            var x2 = overlayProjection.fromLatLngToPoint(l2).x;
            var y2 = overlayProjection.fromLatLngToPoint(l2).y;
            var prP = overlayProjection.fromLatLngToPoint(p);
            var x3 = prP.x;
            var y3 = prP.y;
            if (Math.abs(x2-x1) > EPS) { //can check using x
                return (x1 <= x3 && x3 <= x2) || (x1 >= x3 && x3 >= x2)
            } else {
                return (y1 <= y3 && y3 <= y2) || (y1 >= y3 && y3 >= y2)
            }
        }

        function setupHandler(handler, marker) {
            google.maps.event.addListener(handler, "dragstart", function (pp) {
                this.setIcon(ZORRO_ICON);
            });

            google.maps.event.addListener(handler, "drag", function (pp) {
                movePathLine(marker, pp);
            });

            google.maps.event.addListener(handler, "dragend", function (pp) {
                this.setPosition(marker.getPosition());
                this.setIcon(PATH_LINE_ICON);
                handler.pathLine.setPath([handler.pathLine.p1marker.getPosition(), handler.pathLine.p2marker.getPosition()]);
                var newPathLines = [];  // imitate pathLines creation to cause change of grid.pathLines object

                grid.pathLines.forEach(function(item) {
                    newPathLines.push(item);
                });
                grid.pathLines = newPathLines;
                drawScoutPoints(handler.pathLine);
                updateInfo();

                $rootScope.$safeApply(() => {
                    $rootScope.$broadcast('mapService:gridCompleted', grid);
                });
            });
        }

        function drawPath() {
            var i;
            var p11, p12, p21, p22;
            var p1, p2;
            var pathLine;
            if (alongBands == false) {
                for (i = 0; i < cols; i++) {
                    if (i == 0) {
                        p11 = grid.polyline.getPath().getAt(3);
                        p21 = grid.polyline.getPath().getAt(0);
                        if (cols == 1) {
                            p12 = grid.polyline.getPath().getAt(2);
                            p22 = grid.polyline.getPath().getAt(1);
                        } else {
                            p12 = grid.cols[i].getPath().getAt(1);
                            p22 = grid.cols[i].getPath().getAt(0);
                        }
                    } else {
                        if (i == cols-1) {
                            p12 = grid.polyline.getPath().getAt(2);
                            p22 = grid.polyline.getPath().getAt(1);
                        } else {
                            p12 = grid.cols[i].getPath().getAt(1);
                            p22 = grid.cols[i].getPath().getAt(0);
                        }
                        p11 = grid.cols[i - 1].getPath().getAt(1);
                        p21 = grid.cols[i - 1].getPath().getAt(0);
                    }

                    p1 = randomOnMiddle(p11, p12, i % 2 ? 1 : 0);
                    p2 = randomOnMiddle(p21, p22, i % 2 ? 0 : 1);

                    pathLine = createPathline(p1, p2, p11, p12, p21, p22, i % 2);
                    pathLine.col = i;
                    drawScoutPoints(pathLine);
                }
            } else {
                var h = google.maps.geometry.spherical.computeHeading(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1));
                var height = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(1), grid.polyline.getPath().getAt(2)) / rows;
                var cnt = 0;
                for (i = rows - 1; i + 1 >= 0; i-=combineGrid) {
                    if (i - combineGrid + 1 >= 0) {
                        p11 = google.maps.geometry.spherical.computeOffset(grid.polyline.getPath().getAt(0), height * (1 + i - combineGrid), h + 90);
                        p12 = google.maps.geometry.spherical.computeOffset(p11, height * combineGrid, h + 90);
                        p21 = google.maps.geometry.spherical.computeOffset(grid.polyline.getPath().getAt(1), height * (1 + i - combineGrid), h + 90);
                        p22 = google.maps.geometry.spherical.computeOffset(p21, height * combineGrid, h + 90);

                        p1 = randomOnMiddle(p11, p12, cnt % 2 ? 1 : 0);
                        p2 = randomOnMiddle(p21, p22, cnt % 2 ? 0 : 1);

                        pathLine = createPathline(p1, p2, p11, p12, p21, p22, cnt % 2);
                        pathLine.row = i;
                        drawScoutPoints(pathLine);
                        cnt++;
                    }
                }
            }
        }

        function createPathline(p1, p2, p11, p12, p21, p22, d) {
            var pathLine = new google.maps.Polyline({
                map: _mapService.vars.gmap,
                path: [p1, p2],
                editable: false,
                options: {strokeColor: "#f0bd5d", strokeWeight: 1},
                zIndex: CONSTANT.ZINDEX.PATHLINE
            });

            var pathLineMarkerOptions = {
                crossOnDrag: false,
                icon: PATH_LINE_ICON,
                zIndex: CONSTANT.ZINDEX.PATHLINE_MARKER
            };

            pathLine.p1marker = new google.maps.Marker({position: p1, map: _mapService.vars.gmap, options: pathLineMarkerOptions});
            pathLine.p1marker.a1 = p11;
            pathLine.p1marker.a2 = p12;
            pathLine.p2marker = new google.maps.Marker({position: p2, map: _mapService.vars.gmap, options: pathLineMarkerOptions});
            pathLine.p2marker.a1 = p21;
            pathLine.p2marker.a2 = p22;
            pathLine.p1markerHandler = new google.maps.Marker({position: p1, map: _mapService.vars.gmap, draggable: true, options: pathLineMarkerOptions});
            pathLine.p1markerHandler.pathLine = pathLine;
            pathLine.p2markerHandler = new google.maps.Marker({position: p2, map: _mapService.vars.gmap, draggable: true, options: pathLineMarkerOptions});
            pathLine.p2markerHandler.pathLine = pathLine;
            pathLine.direction = d;

            setupHandler(pathLine.p1markerHandler, pathLine.p1marker);
            setupHandler(pathLine.p2markerHandler, pathLine.p2marker);

            grid.pathLines.push(pathLine);
            return pathLine;
        }

        function path2JTS(boundaries) {
            var coordinates = [];
            if (boundaries.getLength() > 0) {
                for (var i = 0; i < boundaries.getLength(); i++) {
                    var p = boundaries.getAt(i);
                    coordinates.push(new jsts.geom.Coordinate(p.lat(), p.lng()));
                }
                var p = boundaries.getAt(0);
                coordinates.push(new jsts.geom.Coordinate(p.lat(), p.lng()));
            }

            return coordinates;
        }

        function latLng2JTS(latLng) {
            return new jsts.geom.Coordinate(latLng.lat(), latLng.lng());
        }

        function drawScoutPoints(pathLine) {
            pathLine.scoutPoints && pathLine.scoutPoints.forEach(scoutPoint => scoutPoint.setMap(null));
            pathLine.scoutPoints = [];

            var geometryFactory = new jsts.geom.GeometryFactory();
            var shell = geometryFactory.createLinearRing(path2JTS(_mapService.vars.fieldShapes[fieldId].polygon.getPath()));
            var fieldPolygon = geometryFactory.createPolygon(shell);
            var i, segmentLen, h;
            var pp1, pp2, marker;

            if (alongBands) {
                segmentLen = google.maps.geometry.spherical.computeDistanceBetween(pathLine.getPath().getAt(0), pathLine.getPath().getAt(1)) / cols;
                h = google.maps.geometry.spherical.computeHeading(pathLine.getPath().getAt(0), pathLine.getPath().getAt(1));
                for (i = 0; i < cols; i++) {
                    pp1 = google.maps.geometry.spherical.computeOffset(pathLine.getPath().getAt(0), segmentLen * i, h);
                    pp2 = google.maps.geometry.spherical.computeOffset(pp1, segmentLen, h);
                    marker = createScoutPointMarker(geometryFactory.createLineString([latLng2JTS(pp1), latLng2JTS(pp2)]), fieldPolygon, pp1, h, segmentLen, 1);
                    if (marker != null) {
                        pathLine.scoutPoints.push(marker);
                        var ppp = projectPointOnLine(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(1), marker.getPosition());
                        var cellHeight = google.maps.geometry.spherical.computeDistanceBetween(grid.polyline.getPath().getAt(0), grid.polyline.getPath().getAt(3)) / rows;
                        marker.row = Math.floor(google.maps.geometry.spherical.computeDistanceBetween(ppp, marker.getPosition()) / cellHeight);
                        marker.col = i;
                        if (combineGrid > 1) {
                            marker.combinedCell = findCombinedCellByIndex(marker.row, marker.col);
                        }
                    }
                }
            } else {
                segmentLen = google.maps.geometry.spherical.computeDistanceBetween(pathLine.getPath().getAt(0), pathLine.getPath().getAt(1)) / rows;
                h = google.maps.geometry.spherical.computeHeading(pathLine.getPath().getAt(1), pathLine.getPath().getAt(0));
                for (i = rows-1; i + 1 >= 0; i-=combineGrid) {
                    if (i - combineGrid + 1 >= 0) {
                        pp1 = google.maps.geometry.spherical.computeOffset(pathLine.getPath().getAt(1), segmentLen * (1 + i - combineGrid), h);
                        pp2 = google.maps.geometry.spherical.computeOffset(pp1, segmentLen * combineGrid, h);
                        marker = createScoutPointMarker(geometryFactory.createLineString([latLng2JTS(pp1), latLng2JTS(pp2)]), fieldPolygon, pp1, h, segmentLen, combineGrid);
                        if (marker != null) {
                            pathLine.scoutPoints.push(marker);
                            marker.row = Math.floor(google.maps.geometry.spherical.computeDistanceBetween(pathLine.getPath().getAt(1), marker.getPosition()) / segmentLen);
                            marker.col = pathLine.col;
                            if (combineGrid > 1) {
                                marker.combinedCell = findCombinedCellByIndex(marker.row, marker.col);
                            }
                        }
                    }
                }
            }
        }

        function findCombinedCellByIndex(row, col) {
            var result = null;
            _.each(grid.combinedCells, function (cell) {
                _.each(cell.combinedCellIndexes, function (arr) {
                    if (arr[0] == row && arr[1] == col) {
                        result = cell;
                    }
                });
            });

            return result;
        }

        function createScoutPointMarker(pathSegment, fieldPolygon, pp1, h, segmentLen, combine) {
            var pp = null;
            var marker = null;
            var gap = 0.15;
            if (fieldPolygon.contains(pathSegment)) {
                pp = google.maps.geometry.spherical.computeOffset(pp1, segmentLen * (combine * (gap + (1-2*gap)*Math.random())), h);
            } else if (fieldPolygon.intersects(pathSegment)) {
                var linePart = fieldPolygon.intersection(pathSegment);

                if ("MultiLineString" == linePart.getGeometryType()) {
                    var n = Math.round(Math.random()*(linePart.getNumGeometries()-1));
                    linePart = linePart.getGeometryN(n);
                }

                if (linePart && linePart.getNumPoints() > 1) {
                    var k = (gap + (1-2*gap)*Math.random());
                    var x = linePart.getPointN(0).getX() + (linePart.getPointN(1).getX() - linePart.getPointN(0).getX()) * k;
                    var y = linePart.getPointN(0).getY() + (linePart.getPointN(1).getY() - linePart.getPointN(0).getY()) * k;
                    pp = new google.maps.LatLng(x, y);
                }
            }

            if (pp != null && google.maps.geometry.poly.containsLocation(pp, _mapService.vars.fieldShapes[fieldId].polygon)) {

                marker = new google.maps.Marker({
                    position: pp,
                    activities: {},
                    map: _mapService.vars.gmap, options: {draggable: false},
                    icon: SCOUT_POINT_ICON,
                    zIndex: CONSTANT.ZINDEX.POINTS
                });

                marker.addListener('click', function(e) {
                    var marker = this;
                    if (marker.activities) {
                        var scope = $rootScope.$new();
                        angular.extend(scope, {marker: this});
                        var content = angular.element("<div style='line-height:1.35;overflow:hidden;white-space:nowrap;'><frt-activity-types marker='marker'></frt-activity-types></div>"),
                            compiled = $compile(content)(scope);
                        infowindow.setContent(content[0]);
                        infowindow.open(_mapService.vars.gmap, this);
                    }
                });
            }
            return marker;
        }

        function randomOnMiddle(p1, p2, middle) {
            var len = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
            var h = google.maps.geometry.spherical.computeHeading(p1, p2);
            var dist = len * Math.random() * 0.5;
            if (middle == 1) {
                dist = dist + len / 2;
            }
            return google.maps.geometry.spherical.computeOffset(p1, dist, h);
        }

        function splitLine(p1, p2, parts) {
            var h = google.maps.geometry.spherical.computeHeading(p1, p2);
            var len = google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / parts;
            var result = [];
            for (var i = 1; i < parts; i++) {
                result.push(google.maps.geometry.spherical.computeOffset(p1, len * i, h));
            }
            return result;
        }

        function middle(p1, p2) {
            return new google.maps.LatLng((p1.lat() + p2.lat()) / 2, (p1.lng() + p2.lng()) / 2);
        }

        return _mapAddTaskService;
    });